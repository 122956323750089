import React, { useState, useEffect } from 'react'
import { Table, Flex, Card } from 'antd'
import styles from './EditableTable.module.css'
import { cancelAnalysis, editAnalysis, getAnalyses } from '../services'
import { EditableCell } from './EditableCell'
import { EditableRow } from './EditableRow'
import { AnalysesTableColumns } from './Columns/AnalysesTableColumns'
import { BulkActions } from './BulkActions/BulkActions'
import SaveScrollPosition from '../../Common/SaveScrollPosition'

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
}

export const EditableAnalysesTable = ({ workflow, refresh, orderId = null }) => {
  const [dataSource, setDataSource] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState<any>({})
  const [sorter, setSorter] = useState<any>({})
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0)
  const [hasSelected, setHasSelected] = useState(false)
  const [selectedIds, setSelectedIds] = useState<any>([])
  const [sameTicketIds, setSameTicketIds] = useState(false)
  const [notUploaded, setNotUploaded] = useState<boolean>(false)
  const [notUploadedCase, setNotUploadedCase] = useState<any>({})
  const [caseWorkflow, setCaseWorkflow] = useState<any>([])
  const [selectedCaseIds, setSelectedCaseIds] = useState<any>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [includeHidden, setIncludeHidden] = useState<boolean>(false)
  const [isSelectedCaseInView, setIsSelectedCaseInView] = useState<boolean>(true)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  })

  useEffect(() => {
    fetchData()
  }, [refresh, search, pagination, filters, sorter, includeHidden])

  useEffect(() => {
    setIsSelectedCaseInView(checkIsSelectedCaseInView(selectedIds, dataSource))
  }, [dataSource, selectedIds])

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected(!!selectedRowKeys.length)
      const caseWorkflow = selectedRows.map((row) => row.workflow)
      const ids = selectedRows.map((row) => row.id)
      const caseIds = selectedRows.map((row) => row.case_id)
      const ticketIds = selectedRows.map((row) => row.ticket_id)
      const notUploaded = selectedRows.map((row) => row.uploaded_at)
      const notUploadedCases = selectedRows
        .filter((row) => row.uploaded_at === null)
        .map((row) => ({
          caseId: row.case_id,
        }))
      setSelectedIds(ids)
      setSelectedCaseIds(caseIds)
      setSameTicketIds(ticketIds.every((id) => id === ticketIds[0]))
      setNotUploaded(notUploaded.some((date) => !date))
      setNotUploadedCase(notUploadedCases)
      setSelectedRowKeys(selectedRowKeys)
      setCaseWorkflow(caseWorkflow)
    },
  }

  const checkIsSelectedCaseInView = (ids, data) => {
    if (data.length > 0) {
      const dataIdArray = data.map((item) => item.id)
      for (const id of ids) {
        if (!dataIdArray.includes(id)) {
          return false
        }
      }
      return true
    }
    return false
  }

  const resetSelectedRows = () => {
    setSelectedIds([])
    setSelectedCaseIds([])
    setHasSelected(false)
    setSelectedRowKeys([])
  }

  const fetchData = async () => {
    setIsLoading(true)
    const response = await getAnalyses(
      search,
      workflow,
      orderId,
      pagination,
      filters,
      sorter,
      includeHidden
    )
    setTotal(response.total_count)
    setDataSource(response.analyses)
    setIsLoading(false)
  }

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    setFilters(newFilters)
    setSorter(newSorter)
    setPagination(newPagination)
  }

  const handleVisibilityChange = (id, visible) => {
    editAnalysis(undefined, id, 'visible', !visible).then(() => {
      const data = [...dataSource]
      const newData = data.map((item) => updateItem(item, id, undefined, 'visible'))
      setDataSource(newData)
    })
  }

  const handleStatusChange = (id, newStatus: string) => {
    editAnalysis(undefined, id, 'status', undefined, undefined, newStatus).then((response) => {
      const data = [...dataSource]
      const newData = data.map((item) => updateItem(item, id, response, 'status'))
      setDataSource(newData)
    })
  }

  const handleDelivered = (id, delivered: boolean) => {
    editAnalysis(undefined, id, 'delivered', undefined, undefined, undefined, delivered).then(
      (response) => {
        const data = [...dataSource]
        const newData = data.map((item) => updateItem(item, id, response, 'delivered'))
        setDataSource(newData)
      }
    )
  }

  const handleCancelAction = (id) => {
    cancelAnalysis(id).then((response) => {
      const newData = dataSource.map((analysis) => (analysis.id === id ? response : analysis))
      setDataSource(newData)
    })
  }

  const bulkActionUpdateHandler = async (analysesToUpdate) => {
    analysesToUpdate = await analysesToUpdate
    if (analysesToUpdate) {
      const updatedData = dataSource.map((item) => {
        const updatedItem = analysesToUpdate.find((updated) => updated.id === item.id)
        return updatedItem ? { ...item, ...updatedItem } : item
      })
      setDataSource(updatedData)
    }
  }

  const updateItem = (item, id, response, type) => {
    if (item.id === id) {
      switch (type) {
        case 'status':
          return {
            ...item,
            status: response.status,
          }
        case 'visible':
          return {
            ...item,
            is_visible: !item.is_visible,
          }
        case 'delivered':
          return {
            ...item,
            is_delivered: response.is_delivered,
            delivered_by: response.delivered_by,
            delivered_date: response.delivered_date,
          }
        default:
          return item
      }
    }
    return item
  }

  const searchData = (value) => {
    setSearch(value)
  }

  const handleSave = (row) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.id === item.id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    setDataSource(newData)
  }

  const columns = AnalysesTableColumns(
    dataSource,
    handleVisibilityChange,
    handleStatusChange,
    handleSave,
    handleDelivered,
    handleCancelAction,
    orderId
  )

  const includeHiddenToggle = () => {
    setIncludeHidden(!includeHidden)
  }

  return (
    <>
      {!isLoading && <SaveScrollPosition />}
      <Card>
        <Flex gap="middle" vertical>
          <BulkActions
            hasSelected={hasSelected}
            ids={selectedIds}
            caseIds={selectedCaseIds}
            updateHandler={bulkActionUpdateHandler}
            searchData={searchData}
            resetSelectedRows={resetSelectedRows}
            orderId={orderId}
            sameTicketIds={sameTicketIds}
            notUploaded={notUploaded}
            notUploadedCases={notUploadedCase}
            workflow={caseWorkflow}
            includeHidden={includeHidden}
            includeHiddenToggle={includeHiddenToggle}
            isSelectedCaseInView={isSelectedCaseInView}
          />
          <Table
            components={components}
            bordered
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            pagination={{ ...pagination, total }}
            loading={isLoading}
            onChange={handleTableChange}
            sticky={{
              offsetHeader: orderId ? 140 : 183,
            }}
            rowSelection={{
              type: selectedIds,
              ...rowSelection,
            }}
            rowClassName={(record: { is_delivered: boolean }) => {
              return record.is_delivered ? `${styles.customRow} ${styles.signed}` : ''
            }}
          />
        </Flex>
      </Card>
    </>
  )
}
